<template>
  <div>
    <vx-card actionable class="cardx">
      <vs-row vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
          ><h5>Enrollment Summary</h5></vs-col
        >
        <vs-col
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="center"
          vs-offset="3"
          vs-w="6"
        >
          <date-range-picker
            ref="picker"
            :opens="'center'"
            :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            style="width: 240px"
            :timePicker24Hour="true"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
          >
          </date-range-picker>

          <vs-button
            color="dark"
            style="margin-left: 2%"
            type="filled"
            @click="generateReport()"
            >Generate Report</vs-button
          >
        </vs-col>
      </vs-row>
      <vs-row
        style="margin-top: 2%"
        v-if="exportcsvshow !== false && courses.length > 0"
      >
        <vs-col vs-offset="8" vs-w="4" style="text-align: right">
          <vue-json-to-csv
            :json-data="exportdata"
            :csv-title="
              'enrollments-summary' + '-' + startdatecsv + '-' + enddatecsv
            "
          >
            <vs-button type="filled" color="dark">Export CSV</vs-button>
          </vue-json-to-csv>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col>
          <vs-table :data="Enrollments_Summary" :headdata="courses">
            <template slot="thead" v-if="courses.length > 0">
              <vs-th>Reporting Manager</vs-th>
            </template>
            <template
              slot="thead"
              :headdata="tr"
              v-for="(tr, indextr) in courses"
            >
              <vs-th :key="indextr">{{ tr.toUpperCase() }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody ref="tbody">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <div>{{ tr.reporting_manager }}</div>
                  </vs-td>

                  <vs-td v-for="(data, i) in Object.values(tr.data)" :key="i">
                    <div>{{ data }}</div>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </vs-col>
      </vs-row>
      <!-- <vs-row style="margin-top: 3%">
        <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
          <vs-chip color="primary" style="margin-right: 2%">
            <b>{{ Total }}</b>
          </vs-chip>
          <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
        </vs-col>
      </vs-row> -->
    </vx-card>
  </div>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import ApiServices from "../ApiServices.js";
export default {
  props: ["Enrollmemt_Summary"],
  data() {
    return {
      dateRange: {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          1
        ),
        endDate: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      },
      startdatecsv: "",
      enddatecsv: "",
      Enrollments_Summary: [],
      courses: [],
      exportdata: [],
      exportcsvshow: false,
    };
  },
  components: {
    DateRangePicker,
    VueJsonToCsv,
  },
  watch: {
    dateRange() {
      this.enrollmentSummaryRaw();
      this.enrollmentSummary();
    },
  },
  methods: {
    generateReport() {
      this.enrollmentSummary();
      this.enrollmentSummaryRaw();
    },
    enrollmentSummary() {
      this.$vs.loading();
      let fromdate = moment
        .unix(this.dateRange.startDate / 1000)
        .format("YYYY-MM-DD");
      let todate = moment
        .unix(this.dateRange.endDate / 1000)
        .format("YYYY-MM-DD");
      let obj = {
        start_date: fromdate,
        end_date: todate,
      };
      ApiServices.getEnrollmentsSummary(obj)
        .then((response) => {
          if (response.data.status === "error") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
            });
          } else {
            this.Enrollments_Summary = [];
            this.Enrollments_Summary = response.data;
            this.sortOn(this.Enrollments_Summary, "reporting_manager");
            this.$vs.loading.close();
            this.courses = JSON.parse(
              JSON.stringify(Object.keys(response.data[0].data))
            );
            console.log("response", response.data);
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    enrollmentSummaryRaw() {
      this.exportdata = [];
      this.startdatecsv = moment(this.dateRange.startDate).format("YYYY-MM-DD");
      this.enddatecsv = moment(this.dateRange.endDate).format("YYYY-MM-DD");
      let obj = {
        start_date: this.startdatecsv,
        end_date: this.enddatecsv,
      };

      this.$vs.loading();
      ApiServices.getEnrollmentsRaw(obj)
        .then((response) => {
          this.exportdata = JSON.parse(JSON.stringify(response.data));

          if (response.status === "error") {
            this.exportcsvshow = false;
            this.$vs.loading.close();
          } else {
            this.exportcsvshow = true;
            console.log("export data", response);
            this.$vs.loading.close();
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    sortOn(arr, prop) {
      arr.sort(function (a, b) {
        if (a[prop] < b[prop]) {
          return -1;
        } else if (a[prop] > b[prop]) {
          return 1;
        } else {
          return 0;
        }
      });
    },
  },
};
</script>

<style>
</style>